import "./App.css";
// import GithubProject from "./components/GithubProject";
// import Navigation from "./components/Navigation";
// import { useState, useEffect } from "react";
// import axios from "axios";

function App() {
  // const [projects, setProjects] = useState(0);
  // const [dataLoaded, setDataLoaded] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get("https://api.github.com/users/SippinOnJuiceBox/repos")
  //     .then(function (response) {
  //       setProjects(response.data);
  //       setDataLoaded(true);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <div className="App">
      <h1>Eshan Betrabet</h1>
      <p className="about-me">
        I am in my final year at Carleton University pursuing a B.S. in
        Cognitive Science.
      </p>
      <p className="about-me">
        busy building{" "}
        <strong>
          <i>cool</i>
        </strong>{" "}
        software
      </p>
      <li>
        <a href="https://calendly.com/eshanbetrabet/30min">calendly</a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/eshan-betrabet/">linkedin</a>
      </li>
      <li>
        <a href="https://github.com/SippinOnJuiceBox">github</a>
      </li>
      <li>
        <p>discord: sippinonjuicebox</p>
      </li>
      {/* <Navigation />
      <div className="user-info">
        <header className="page-title">Eshan Betrabet</header>
        <h1 className="what-I-do">Full stack developer · UI Designer</h1>
        <p className="about-me">
          I am a third year at Carleton University pursuing a B.S. in Cognitive
          Science & Computing. I have experience in a variety of programming
          languages including Java, Javascript, Python, and C++. I am always
          interested in learning new technologies.
        </p>
      </div>
      <div className="project-library">
        {dataLoaded
          ? projects.map(function (project) {
              return <GithubProject props={project} />;
            })
          : function () {
              return <p>loading</p>;
            }}
      </div> */}
    </div>
  );
}

export default App;
